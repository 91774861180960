@import "config/style/overides";

// TODO: This styling should eventually be part of the standard markdown rendering across the portals and synapse.org
// Its starting to diverge from synapse.org markdown styling, since there has been a lot of wikis made specifically
// for the synapse.org styling I'm keeping this seperate so the new properties can be easily tracked and tied to 
// specific JIRA issues.

.markdown {
  color: #515359 !important;
  
  .h2, h2 {
    font-size: 21px;
    text-transform: uppercase;
    font-weight: 700;
    color: #515359;
    margin-top: 42px;
  }
    
  .h3, h3 {
    font-size: 18px;
    font-weight: 700;
    color: #515359;
  }
    
  h3 {
    padding: 0px;
  }
    
  p {
    color: #515359;
    margin: 21px 0;
    padding: 0;
  }
  
  // only select anchors without SRC-primary-button class
  a:not(.SRC-primary-button), details > summary {
    font-weight: 700;
    color: $primary-action-color;
  }

  // PORTALS-700
  details > summary {
    list-style: none;
    cursor: pointer;
    display: inline;
    margin-left: 2px;
    border-bottom: 1px solid transparent;
  }
  details > summary:hover {
    border-bottom: 1px solid $primary-action-color;
  }
  details > summary::-webkit-details-marker {
    display:none;
  }
  // SWC-4987 (css hack). change position when open. set the visiblity of the element to hidden, and make the pseudo-element visible!
	details[open] {
		position: relative;
		padding-bottom: 25px;
		summary {
			position: absolute;
			bottom: 0px;
			visibility: hidden;
		}
		summary::before {
			content: 'Show Less';
			visibility: visible;
		}
	}
}

// PORTALS-679
.markdown p {
  margin: 0;
  padding-bottom: 12px;
  font-size: 15px;
  line-height: 23px;
}

.markdown p a {
  color: #4d5491; /* action color */
  font-weight: 700;
}

.markdown h4 {
  padding: 7px 0 14px 0;
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
}
.markdown h5 {
  padding: 0;
  margin: 0;
  color: #ABABAC; /* deemphasized color */
}

.title + .markdown {
  margin-top: 42px;
}


// PORTALS-767

/* Makes table layout better overall*/
.markdowntable td, .markdowntable th {
  padding: 10px;
}

/* fixes visual hierarchy overall, particularly on About page */
.markdown h4 {
  font-size: 16px;
}

/* If we can scope this to just News page it would be ideal, but not required for now*/
.whats-new {
  .markdown p + h5 {
    margin-top: 21px;
  }
}