
$primary-text-color: #4d5491;
$primary-action-color: #4d5491;

#header, #footer {
  background-color: $primary-action-color;
}

.SRC-portalCard a {
  color: $primary-action-color !important;
}

.SRC-primary-background-color-border-left {
  border-left-color: $primary-action-color !important;
}
.SRC-mailchimpSubscribeContainer {
  button {
    background-color: $primary-action-color !important;
  }
}

footer {
  img.nav-logo {
    width: 250px;
  }
}

.SRC-chart-link {
  a {
    background: $primary-action-color !important;
  }
}

// Since scss breaks if a file is missing in a local url link these functinons must get manually overwitten
@function getHeader($path) {
  @return url($path + "/header.svg");
}

@function getFooter($path) {
  @return url($path + "/header.svg");
}
