@import "config/style/overides";
@import 'node_modules/synapse-react-client/dist/template_style/Index.scss';

.nav-logo {
  height: 50px;
}

// this pushes the footer to the bottom of the screen
.main {
  min-height: 100vh; /* will cover the 100% of viewport */
  display: block;
  position: relative;
  padding-bottom: 60px; /* height of your footer */
}

.menu-wall {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 900;
  left: 0;
  top: 0;
  background: transparent;
  border: none;
}

.uppercase {
  text-transform: uppercase;
}

.normal-weight {
  font-weight: normal;
}

.center-content {
  display: flex;
  align-items: center;
}
$header-height : 75px;
$banner-height: 47px;

body {
  font-family: 'Lato', sans-serif;
  button:focus {
    // bootstrap override
    outline: $primary-action-color;
  }
}

.nav + .spacer {
  min-height: $header-height;
  height: $header-height;
}

#banner + .nav + .spacer {
  min-height: $header-height + $banner-height;
  height: $header-height + $banner-height;
}

.flex-display {
  display: flex;
}
// avoid having to rewrite this everywhere
%hoverFade {
  transition: all 400ms;
}

%defaultBoxShadow {
  box-shadow: 3px 3px 5px 0 rgba(0,0,0,.5);
}
// end utilities


%center {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

// banner
#banner {
  height: $banner-height;
  width: 100%;
  position: fixed;
  z-index: 6;
  @extend %center;
  font-size: 16px;
  span {
    font-weight: 600;
    margin-right: 5px;
    @extend %center;
    border-radius: 24px;
    width: 65px;
    height: 28px;
    background: #ededed;
  }
  a {
    margin-left: 4px;
    color: black;
    font-weight: 600;
    text-decoration: underline;
  }
}

// nav
.nav-logo-container {
  flex-basis: 50%;
}

.nav-link-container {
  flex-basis: 50%;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 40px;
  @media screen and (max-width: 520px) {
    padding-right: 0px;
  }
}

.nav-button-item {
  font-size: 16px;
}

// if banner exists then make sure the nav sits right below it bet setting its top to the height of 
// the banner above it
#banner + .nav {
  top: $banner-height;
}

.nav {
  padding-bottom: 3px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
  min-height: $header-height;
  height: $header-height;
  position: fixed;
  width: 100%;
  z-index: 6;
  background: rgba(256,256,256,0.9);
  // background: white;
  height: 67px;
  a:hover {
    text-decoration: none;
  }
  a:focus {
    text-decoration: none;
  }
}

%borderStyle {
  border-bottom-style: solid;
  border-bottom-width: 3px;
}

%fillerBorder {
  @extend %borderStyle;
  border-bottom-color:transparent;
}

.nav-button {
  text-transform: uppercase;
  font-weight: bold;
  height: -webkit-fill-available;
  // fallback for mozilla and ie11
  height: 100%;
  font-size: 16px;
  text-align: center;
  justify-content: center;
  color: #404B63;
  text-decoration: none;
  padding: 0px 20px;
  margin: 0px 5px;
  min-width: 100px;
  white-space: nowrap;
  @extend %fillerBorder;
}

%bottomBorderNav {
  @extend %borderStyle;
  border-bottom-color:$primary-action-color;
}
.nav-button-container:hover {
  @extend %hoverFade;
  @extend %bottomBorderNav;
}

.dropdown.nav-button-container.bottom-border {
  @extend %bottomBorderNav;
}

#home-link {
  font-weight: bold;
  font-size: 25px;
  color: $primary-action-color;
  margin-left: 15px;
}

// bootstrap override
.open > a {
  color: $primary-action-color !important;
  background-color: white !important;
  &:focus {
    border-color: unset !important;
  }
  &:hover {
    border-color: unset !important;
  }
}

.dropdown-item {
  color: #515359;
  padding-left: 20px;
  height: 50px;
  width: 230px;
}

.border-bottom-1 {
  border-bottom: solid 1px;
  border-bottom-color:#e5e5e5;
}

a.dropdown-item:hover {
  color: white;
}

// bootstrap override
.dropdown-menu {
  margin: 0px;
  border-radius: 0%;
  padding: 0px;
}
// end nav

// footer
#portal-title-footer {
  flex-basis: 50%;
  font-size: 25px;
  padding-left: 15px;
  a {
    text-decoration: none;
  }
}

#portal-contact-footer {
  flex-basis: 50%;
  display: flex;
  flex-direction: row-reverse;
}

.footer-item {
  padding: 0px 30px;
  text-transform: uppercase;
}

.hand-cursor {
  cursor: pointer;
}

#footer {
  background-image: getFooter("config/style/");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  a {
    color: white;
  }
  height: 60px;
  width: 100%;
}

.SRC-primary-text-color-background {
  background-color: $primary-action-color;
  color: white;
}

#signin-button {
  height: 39px;
  border-radius: 30px;
  padding: 8px 37px;
  border: none;
  &:hover {
    @extend %hoverFade;
    @extend %defaultBoxShadow;
  }
}

#loginPage {
  padding: 30px;
}

.footer-help {
  flex-basis: 50%;
}

// end footer

// header
#header {
  background-image: getHeader("config/style");
  background-repeat: no-repeat;
  background-size: cover;
  height: fit-content;
}

.header-text {
  h2 {
    font-weight: bold;
  }
  height: fit-content;
  color: white;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  p {
    font-size: 16px;
  }
}
// end header

#explore-portal {
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 10px;
}
// ExploreButtons 

.explore-buttons {
  margin-top: 30px;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: flex;
  align-content: center;
  justify-content: space-between;
  button {
    text-transform: uppercase;
    border-radius: 6px;
    letter-spacing: 0.4px; /* make buttons more readable */
    flex-basis: 22%; /* make buttons wider on home page */
    margin: 0px 8px;
    border: none;
    position: relative;
    height: 50px;
    color: white;
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
    // caret dropdown
    &.active-button::after {
      border-top-color: inherit !important;
      border-right: solid 12px transparent;
      border-left: solid 12px transparent;
      border-top: solid 12px;
      transform: translateX(-50%);
      position: absolute;
      z-index: 3;
      content: '';
      top: 100%;
      left: 50%;
      height: 0;
      width: 0;
    }
    &:hover {
      @extend %hoverFade;
      @extend %defaultBoxShadow;
    }
  }
}
// end ExploreButtons

// title
.title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 21px;
  margin: 49px 0 14px 0;
}
// end title
// home page utils
.newContainer {
  position: relative;
  padding: 20px 0px;
}

.bar-loader {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  height: 30px;
}

// end home page utils

// override synapse table label, TODO: add specific class that can get targeted instead
div.SRC-marginBottomTen {
  p {
    margin-top: 15px;
  }
} 

#exploreCount {
  padding-top: 20px;
}

#user-menu-button {
  height: 72px;
  .SRC-userCard {
    width: 28px;
  }
}

.user-menu-dropdown {
  left: -81px;
}

.cookiesBanner {
  background: $primary-action-color;
  height: 50px;
  color: white;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  font-size: 15px;
  padding-left: 20px;
  padding-right: 20px;
  // stick to bottom
  position: fixed;
  bottom: 15px;
  z-index: 6;
  width: 100%;

  a {
    margin: 0px 10px;
    color: white;
  }
  button {
    color: $primary-action-color;
  }
}

.queryCountHeader {
  margin-top: 15px;
  margin-bottom: 20px;
}

.statefulButtonTitle {
  margin-top: 15px;
}